import React from "react";
import { useTranslation } from "react-i18next";

import Helmchron from "../../assets/Img/clients/helmchron.webp";
import Makoreografija from "../../assets/Img/clients/maja.png";
import Bobice from "../../assets/Img/clients/bobice.png";
import Mihailo from "../../assets/Img/clients/miha.png";
import AvanturaSrbija from "../../assets/Img/clients/avanturas.svg";
import rustic from "../../assets/Img/clients/rustic.jpg";
import ObuciCokoladu from "../../assets/Img/clients/Obucicokoladu.png";
import { Container } from "../../style";

export default function Clients() {
  const { t } = useTranslation();
  return (
    <div>
      <Container style={{ paddingTop: 100 }}>
        <div className="clients">
          <h2 style={{ marginTop: 0 }} className="sub-title">
            {t("about.our_clients")}
          </h2>

          <div style={{ margin: "50px 0" }}>
            <div>
              <a
                href="https://www.helmchron.com"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Helmchron} alt="Helmchron Logo" />
              </a>
            </div>
            <div>
              <a
                href="https://www.makoreografija.com"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Makoreografija} alt="Makoreografija Logo" />
              </a>
            </div>

            <div>
              <a
                href="https://avanturasrbija.rs"
                target="_blank"
                rel="noreferrer"
              >
                <img src={AvanturaSrbija} alt="Avantura Srbija Logo" />
              </a>
            </div>

            <div>
              <a href="https://www.bobice.rs" target="_blank" rel="noreferrer">
                <img src={Bobice} alt="Bobice Logo" />
              </a>
            </div>

            <div>
              <a
                href="https://www.rusticwoodboards.com"
                target="_blank"
                rel="noreferrer"
              >
                <img src={rustic} alt="Rustic Wood Boards" />
              </a>
            </div>

            <div>
              <img src={ObuciCokoladu} alt="Obuci Cokoladu" />
            </div>

            <div>
              <a
                href="https://mihailo-fitness.rs"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Mihailo} alt="Mihailo Fitness Logo" />
              </a>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
